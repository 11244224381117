import styles from './Home.module.scss';
import classNames from 'classnames/bind';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import bgHome from 'src/assets/images/bg-home.png';
import samImg from 'src/assets/images/sam.png';
import cartW from 'src/assets/images/icons/cart-w.png';
import vuonSam from 'src/assets/images/cam-ket.png';
import branchSamDH from 'src/assets/images/dai-hoi.png';
import branchSam from 'src/assets/images/img-branch.png';
import branchSam2 from 'src/assets/images/img-branch2.png';
import branchSam3 from 'src/assets/images/img-branch3.png';
import branchSam4 from 'src/assets/images/img-branch4.png';
import samSlogan from 'src/assets/images/sam-slogan.png';
import bannerEvent from 'src/assets/images/banner-event.png';
import denSam from 'src/assets/images/den-than-sam.png';
import bgSoAT from 'src/assets/images/bg-so-at.png';
import bgVector from 'src/assets/images/icons/vector.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-regular-svg-icons';
import { faCalendarDay, faTag } from '@fortawesome/free-solid-svg-icons';

const cx = classNames.bind(styles);

const IMAGE_BASE_URL = '/images';

function CustomArrow(props) {
   return null; // Ẩn mũi tên điều hướng trái phải
}

function Home() {
   const [activeTab, setActiveTab] = useState('new'); // Khởi tạo tab mặc định là 'new'
   const [news, setNews] = useState([]);

   useEffect(() => {
      fetch('/newsData/news.json')
         .then((response) => response.json())
         .then((data) => {
            const parsedData = data.map((newsItem) => ({
               ...newsItem,
               originalDate: newsItem.date_time,
               date_time: new Date(newsItem.date_time.split('/').reverse().join('-')),
            }));
            setNews(parsedData);
         });
   }, []);

   const newsHome = news.sort((a, b) => b.date_time - a.date_time).slice(0, 2);

   const handleTabClick = (tab) => {
      setActiveTab(tab); // Đổi tab khi người dùng nhấn vào tab mới
   };

   const handleNavigation = (e) => {
      // Nếu người dùng nhấn vào nút "Mua hàng" hoặc "Yêu thích", không chuyển trang
      e.preventDefault();
   };

   const sliderHome = {
      dots: false, // Hiển thị chấm tròn chỉ định slide hiện tại
      infinite: true, // Vòng lặp vô hạn
      speed: 1000, // Tốc độ chuyển slide (ms)
      slidesToShow: 1, // Số lượng slide hiển thị cùng lúc
      slidesToScroll: 1, // Số lượng slide cuộn khi chuyển
      autoplay: true, // Tự động chạy
      autoplaySpeed: 2500, // Thời gian chờ giữa các slide (ms)
      fade: true, // Hiệu ứng fade
      cssEase: 'linear', // Kiểu chuyển đổi
      prevArrow: <CustomArrow />,
      nextArrow: <CustomArrow />,
   };

   return (
      <div className={cx('wrapper')}>
         <div className={cx('background-home')} style={{ backgroundImage: `url(${bgHome})` }}>
            <div className={cx('bg-head')}>
               <div className={cx('bg-text')}>
                  {/* <div className={cx('tl-branch')}>Sâm Ngọc Linh</div>
                  <div className={cx('tl-slogan')}>Món quà từ thiên nhiên</div> */}
                  <div className={cx('img-slogan')}>
                     <img src={samSlogan} alt={'slogan sam'} />
                  </div>
                  <NavLink to="/infomation-club" className={cx('kham-pha')}>
                     Khám phá
                  </NavLink>
               </div>
               <div className={cx('img-branch')}>
                  <Slider className={cx('img-branchHome')} {...sliderHome}>
                     <img src={branchSamDH} alt={'Hinh anh Sam Ngoc Linh'} />
                     <img src={branchSam} alt={'Hinh anh Sam Ngoc Linh'} />
                     <img src={branchSam2} alt={'Hinh anh Sam Ngoc Linh'} />
                     <img src={branchSam4} alt={'Hinh anh Sam Ngoc Linh'} />
                     <img src={branchSam3} alt={'Hinh anh Sam Ngoc Linh'} />
                  </Slider>
                  <img className={cx('bg-vector')} src={bgVector} alt={'vector'} />
               </div>
            </div>
         </div>
         {/* <div className={cx('banner-in')}>Thông tin nhanh các tin tức liên quan đến hội sâm</div> */}
         <div className={cx('banner-event')}>
            <img src={bannerEvent} alt={'Dai hoi Sam Ngoc Linh'} />
         </div>
         <div className={cx('container')}>
            <div className={cx('body-intro')}>
               <div className={cx('tl-heading')}>Giới thiệu</div>
               <div className={cx('cont-intro')}>
                  <div className={cx('des-intro')}>
                     <div className={cx('des-part')}>
                        Sâm Ngọc Linh là một loại dược liệu quý hiếm, có nguồn gốc và điều kiện sinh trưởng đặc biệt tại
                        vùng núi Ngọc Linh thuộc hai tỉnh Quảng Nam và Kon Tum.
                     </div>
                     <div className={cx('des-part')}>
                        Tỉnh Quảng Nam đã qui hoạch hơn 19 ngàn héc ta rừng để bảo tồn và phát trển cây sâm Ngọc Linh.
                        Tỉnh Quảng Nam hiện có hơn một ngàn hộ trồng sâm và hàng chục doanh nghiệp tham gia kinh doanh,
                        chế biến Sâm Ngọc Linh, góp phần phát triển kinh tế xã hội địa phương và thúc đẩy chiến lược
                        phát triển Sâm Ngọc Linh của đất nước.
                     </div>
                     <div className={cx('des-part')}>
                        Hội Sâm Ngọc Linh Quảng Nam là nơi tập hợp người trồng sâm, các hợp tác xã, doanh nghiệp hoạt
                        động trong lĩnh vực trồng, kinh doanh và chế biến sâm Ngọc Linh.
                     </div>
                     <div className={cx('des-part')}>
                        Hội Sâm Ngọc Linh Quảng Nam hoạt động với mục tiêu bảo tồn nguồn gen quý của cây Sâm Ngọc Linh;
                        phát triển sản xuất và chế biến Sâm Ngọc Linh trở thành một trong những ngành kinh tế mũi nhọn,
                        góp phần nâng cao thu nhập cho người dân, nhất là vùng đồng bào dân tộc miền núi; gắn việc sản
                        xuất Sâm Ngọc Linh với việc quản lý, bảo vệ và phát triển rừng bền vững; giữ gìn và phát huy bản
                        sắc văn hóa địa phương; nâng cao vị thế của Sâm Ngọc Linh - Quốc bảo Việt Nam trên trường quốc
                        tế.{' '}
                     </div>
                     <div className={cx('des-part')}>
                        Hội Sâm Ngọc Linh Quảng Nam: “Tôn vinh vị thế Quốc bảo Việt Nam!”
                     </div>
                  </div>
                  <div className={cx('img-cont-intro')}>
                     <img src={vuonSam} alt={'vuon sam'} />
                     <div className={cx('cam-ket')}>
                        Chúng tôi cam kết<br></br> về chất lượng sản phẩm
                     </div>
                  </div>
               </div>
            </div>
            <div className={cx('body-proList')}>
               <div className={cx('head-pro')}>
                  <div className={cx('tl-heading')}>Danh mục sản phẩm</div>
                  <NavLink to="/list-production" className={cx('view-all')}>
                     Xem toàn bộ &#187;
                  </NavLink>
               </div>
               <div className={cx('total-proList')}>
                  <div className={cx('proList-item')}>
                     <img src={`${IMAGE_BASE_URL}/danh-muc/ruousam2.png`} alt={'ruou sam'} />
                     <div className={cx('proList-cont')}>
                        <div className={cx('proList-name')}>Rượu sâm</div>
                        <div className={cx('proList-num')}>1 sản phẩm</div>
                     </div>
                  </div>
                  <div className={cx('proList-item')}>
                     <img src={`${IMAGE_BASE_URL}/danh-muc/ruousam2.png`} alt={'ruou sam'} />
                     <div className={cx('proList-cont')}>
                        <div className={cx('proList-name')}>Trà sâm</div>
                        <div className={cx('proList-num')}>0 sản phẩm</div>
                     </div>
                  </div>
                  <div className={cx('proList-item')}>
                     <img src={`${IMAGE_BASE_URL}/danh-muc/samtuoi.png`} alt={'sam tuoi'} />
                     <div className={cx('proList-cont')}>
                        <div className={cx('proList-name')}>Sâm tươi</div>
                        <div className={cx('proList-num')}>0 sản phẩm</div>
                     </div>
                  </div>
                  <div className={cx('proList-item')}>
                     <img src={`${IMAGE_BASE_URL}/danh-muc/ruousam2.png`} alt={'ruou sam'} />
                     <div className={cx('proList-cont')}>
                        <div className={cx('proList-name')}>Viên nang sâm</div>
                        <div className={cx('proList-num')}>1 sản phẩm</div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div className={cx('body-product')}>
            <div className={cx('tl-heading')}>Sản phẩm</div>
            {/* Tabs */}
            <div className={cx('namePro-tab')}>
               <div
                  className={cx('namePro-item', 'pro-newH', { active: activeTab === 'new' })}
                  onClick={() => handleTabClick('new')}
               >
                  Sản phẩm mới
               </div>
               <div
                  className={cx('namePro-item', 'pro-dealH', { active: activeTab === 'deal' })}
                  onClick={() => handleTabClick('deal')}
               >
                  Bán chạy
               </div>
               <div
                  className={cx('namePro-item', 'pro-decuH', { active: activeTab === 'decu' })}
                  onClick={() => handleTabClick('decu')}
               >
                  Đề cử
               </div>
            </div>

            {/* Tab Content */}
            <div className={cx('proList-tab')}>
               {activeTab === 'new' && (
                  <div className={cx('pro-new')}>
                     <div className={cx('proBranch-list')}>
                        {/* Các item sản phẩm */}

                        <NavLink to="/production/subpage" className={cx('proBranch-item')}>
                           <div className={cx('pro-star')}>
                              <img src={`${IMAGE_BASE_URL}/icons/rate5.png`} alt={'5 sao'} />
                           </div>
                           <div className={cx('proBranch-cont')}>
                              <div className={cx('proB-img')}>
                                 <img src={`${IMAGE_BASE_URL}/products/sam-mo.png`} alt={'sam mat ong'} />
                              </div>
                              <div className={cx('proB-tl')}>Sâm ngâm mật ong</div>
                              <div className={cx('proB-price')}>
                                 <div className={cx('price-old')}>Giá</div>
                                 <div className={cx('price-now')}>Liên hệ</div>
                              </div>
                              <div className={cx('proB-act')}>
                                 <div className={cx('btn-pur')} onClick={(e) => handleNavigation(e)}>
                                    <img src={cartW} alt={'cart'} />
                                    <div className={cx('pur-tl')}>Mua hàng</div>
                                 </div>
                                 <div className={cx('btn-tym')} onClick={(e) => handleNavigation(e)}>
                                    <FontAwesomeIcon icon={faHeart} className={cx('icon-tym')} />
                                 </div>
                              </div>
                           </div>
                        </NavLink>
                        <NavLink to="/production/subpage" className={cx('proBranch-item')}>
                           <div className={cx('pro-star')}>
                              <img src={`${IMAGE_BASE_URL}/icons/rate5.png`} alt={'5 sao'} />
                           </div>
                           <div className={cx('proBranch-cont')}>
                              <div className={cx('proB-img')}>
                                 <img src={`${IMAGE_BASE_URL}/products/vien.png`} alt={'vien uong snl'} />
                              </div>
                              <div className={cx('proB-tl')}>Viên uống Sâm Ngọc Linh</div>
                              <div className={cx('proB-price')}>
                                 <div className={cx('price-old')}>Giá</div>
                                 <div className={cx('price-now')}>Liên hệ</div>
                              </div>
                              <div className={cx('proB-act')}>
                                 <div className={cx('btn-pur')} onClick={(e) => handleNavigation(e)}>
                                    <img src={cartW} alt={'cart'} />
                                    <div className={cx('pur-tl')}>Mua hàng</div>
                                 </div>
                                 <div className={cx('btn-tym')} onClick={(e) => handleNavigation(e)}>
                                    <FontAwesomeIcon icon={faHeart} className={cx('icon-tym')} />
                                 </div>
                              </div>
                           </div>
                        </NavLink>
                        <NavLink to="/production/subpage" className={cx('proBranch-item')}>
                           <div className={cx('pro-star')}>
                              <img src={`${IMAGE_BASE_URL}/icons/rate5.png`} alt={'5 sao'} />
                           </div>
                           <div className={cx('proBranch-cont')}>
                              <div className={cx('proB-img')}>
                                 <img src={`${IMAGE_BASE_URL}/products/trasam.png`} alt={'sam mat ong'} />
                              </div>
                              <div className={cx('proB-tl')}>Trà sâm Ngọc Linh TRIMICO</div>
                              <div className={cx('proB-price')}>
                                 <div className={cx('price-old')}>Giá</div>
                                 <div className={cx('price-now')}>Liên hệ</div>
                              </div>
                              <div className={cx('proB-act')}>
                                 <div className={cx('btn-pur')} onClick={(e) => handleNavigation(e)}>
                                    <img src={cartW} alt={'cart'} />
                                    <div className={cx('pur-tl')}>Mua hàng</div>
                                 </div>
                                 <div className={cx('btn-tym')} onClick={(e) => handleNavigation(e)}>
                                    <FontAwesomeIcon icon={faHeart} className={cx('icon-tym')} />
                                 </div>
                              </div>
                           </div>
                        </NavLink>
                        <NavLink to="/production/subpage" className={cx('proBranch-item')}>
                           <div className={cx('pro-star')}>
                              <img src={`${IMAGE_BASE_URL}/icons/rate5.png`} alt={'5 sao'} />
                           </div>
                           <div className={cx('proBranch-cont')}>
                              <div className={cx('proB-img')}>
                                 <img src={`${IMAGE_BASE_URL}/products/dangsam.png`} alt={'vien uong snl'} />
                              </div>
                              <div className={cx('proB-tl')}>Đẳng sâm Ngọc Linh VINASAM</div>
                              <div className={cx('proB-price')}>
                                 <div className={cx('price-old')}>Giá</div>
                                 <div className={cx('price-now')}>Liên hệ</div>
                              </div>
                              <div className={cx('proB-act')}>
                                 <div className={cx('btn-pur')} onClick={(e) => handleNavigation(e)}>
                                    <img src={cartW} alt={'cart'} />
                                    <div className={cx('pur-tl')}>Mua hàng</div>
                                 </div>
                                 <div className={cx('btn-tym')} onClick={(e) => handleNavigation(e)}>
                                    <FontAwesomeIcon icon={faHeart} className={cx('icon-tym')} />
                                 </div>
                              </div>
                           </div>
                        </NavLink>
                     </div>
                  </div>
               )}
               {activeTab === 'deal' && (
                  <div className={cx('proBranch-list')}>
                     <div className={cx('pro-deal')}>
                        <p>Hiển thị sản phẩm bán chạy...</p>
                     </div>
                  </div>
               )}
               {activeTab === 'decu' && (
                  <div className={cx('pro-decu')}>
                     <div className={cx('proBranch-list')}>
                        <p>Hiển thị sản phẩm đề cử...</p>
                     </div>
                  </div>
               )}
            </div>
         </div>
         <div className={cx('banner-denSam')}>
            <img src={denSam} alt={'Den Than Sam'} />
            <div className={cx('denSam-tl')}>Đền Thần Sâm Ngọc Linh ở Trà Linh, huyện Nam Trà My, tỉnh Quảng Nam</div>
         </div>
         <div className={cx('container')}>
            <div className={cx('body-daugia')}>
               <div className={cx('head-pro')}>
                  <div className={cx('tl-heading')}>Đấu giá</div>
                  <NavLink to="/auction" className={cx('view-all')}>
                     Xem toàn bộ &#187;
                  </NavLink>
               </div>
               <div className={cx('total-daugia')}>
                  <div className={cx('daugia-list')}>
                     <div className={cx('daugia-item')}>
                        <img src={`${IMAGE_BASE_URL}/danh-muc/tin1.png`} alt={'vien uong snl'} />
                     </div>
                     <div className={cx('daugia-item')}>
                        <img src={`${IMAGE_BASE_URL}/danh-muc/tin2.png`} alt={'vien uong snl'} />
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div className={cx('so-an-tuong')} style={{ backgroundImage: `url(${bgSoAT})` }}>
            <div className={cx('tl-heading2')}>Những con số ấn tượng</div>
            <div className={cx('cont-soAT')}>
               Tỉnh Quảng Nam đã qui hoạch hơn 19 ngàn héc ta rừng để bảo tồn và phát trển cây sâm Ngọc Linh. Tỉnh Quảng
               Nam hiện có hơn một ngàn hộ trồng sâm và hàng chục doanh nghiệp tham gia kinh doanh, chế biến Sâm Ngọc
               Linh, góp phần phát triển kinh tế xã hội địa phương và thúc đẩy chiến lược phát triển Sâm Ngọc Linh của
               đất nước.
            </div>
            <div className={cx('view-soAT')}>
               <div className={cx('item-soAT')}>
                  <div className={cx('num-soAT')}>19000</div>
                  <div className={cx('text-soAT')}>Hecta rừng bảo tồn và phát triển sâm</div>
               </div>
               <div className={cx('item-soAT')}>
                  <div className={cx('num-soAT', 'hl-num')}>1000+</div>
                  <div className={cx('text-soAT')}>Hộ trồng sâm</div>
               </div>
               <div className={cx('item-soAT')}>
                  <div className={cx('num-soAT')}>20+</div>
                  <div className={cx('text-soAT')}>Doanh nghiệp tham gia</div>
               </div>
            </div>
         </div>
         <div className={cx('container')}>
            <div className={cx('body-news')}>
               <div className={cx('tl-heading')}>Tin tức & Sự kiện</div>
               <div className={cx('total-news')}>
                  <div className={cx('news-list')}>
                     {newsHome.map((item) => (
                        <Link to={`/news-event/${item.id}`} key={item.id} className={cx('news-item')}>
                           <div className={cx('news-frame')}></div>
                           <div className={cx('news-cont')}>
                              <div className={cx('news-img')}>
                                 <img src={`${IMAGE_BASE_URL}/news/${item.image}`} alt={item.title} />
                              </div>
                              <div className={cx('news-info')}>
                                 <div className={cx('news-tag')}>
                                    <FontAwesomeIcon icon={faTag} className={cx('icon-tag')} />
                                    <div className={cx('name-tag')}>{item.category}</div>
                                 </div>
                                 <div className={cx('news-tl')}>{item.title}</div>
                                 <div className={cx('news-text')}>{item.content}</div>
                                 <div className={cx('news-line')}></div>
                                 <div className={cx('news-date')}>
                                    <FontAwesomeIcon icon={faCalendarDay} className={cx('icon-date')} />
                                    <div className={cx('date-now')}>{item.originalDate}</div>
                                 </div>
                              </div>
                           </div>
                        </Link>
                     ))}
                  </div>
                  <div className={cx('btn-view')}>
                     <NavLink to="/news-event" className={cx('btn-viewAll')}>
                        Xem tất cả
                     </NavLink>
                  </div>
               </div>
            </div>
         </div>
         <div className={cx('dang-ky')}>
            <div className={cx('form-dk')}>
               <div className={cx('dk-tl')}>Đăng ký hội viên</div>
               <div className={cx('dk-des')}>
                  Đăng ký hội viên để tham gia bán hàng và không bỏ lỡ các chính sách hỗ trợ hấp dẫn
               </div>
               <div className={cx('dk-enter')}>
                  <input type="text" placeholder="Nhập email của bạn" className={cx('email-enter')} />
                  <NavLink to="/member-register" className={cx('btn-dk')}>
                     Đăng ký
                  </NavLink>
               </div>
            </div>
            <div className={cx('img-sam')}>
               <img src={samImg} alt={'cu sam'} />
            </div>
         </div>
      </div>
   );
}

export default Home;
