import styles from './NewsEvent.module.scss';
import classNames from 'classnames/bind';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import bgPage from 'src/assets/images/bgPageEvent.png';

const cx = classNames.bind(styles);
const IMAGE_BASE_URL = '/images/news';

function NewsEvent() {
   const [news, setNews] = useState([]);

   useEffect(() => {
      window.scrollTo(0, 0); // Cuộn trang lên đầu

      fetch('/newsData/news.json')
         .then((response) => response.json())
         .then((data) => setNews(data));
   }, []);

   if (news.length === 0) {
      return <div>Loading...</div>; // Loading state
   }

   const hlNews = news[news.length - 1]; // Lấy phần tử cuối cùng làm nổi bật
   const otherNews = news.slice(0, news.length - 1); // Các phần tử còn lại
   return (
      <div className={cx('wrapper', 'body-proList')}>
         <div className={cx('background-page')} style={{ backgroundImage: `url(${bgPage})` }}>
            <div className={cx('tl-subMenu')}>Tin tức - Sự kiện</div>
         </div>
         <div className={cx('container')}>
            <div className={cx('body-news')}>
               <div className={cx('body-hl')}>
                  <Link to={`/news-event/${hlNews.id}`} key={hlNews.id}>
                     <div className={cx('news-hl')}>
                        <div className={cx('hl-left')}>
                           <div className={cx('news-name-hl')}>{hlNews.title}</div>
                           <div className={cx('news-content-hl')}>{hlNews.content}</div>
                        </div>
                        <div className={cx('hl-right')}>
                           <img
                              className={cx('news-img-hl')}
                              src={`${IMAGE_BASE_URL}/${hlNews.image}`}
                              alt={hlNews.title}
                           />
                        </div>
                     </div>
                  </Link>
               </div>
               <div className={cx('news-list')}>
                  {otherNews.map((item) => (
                     <div key={item.id} className={cx('news-item')}>
                        <Link to={`/news-event/${item.id}`}>
                           <div className={cx('img-body')}>
                              <img
                                 className={cx('news-img')}
                                 src={`${IMAGE_BASE_URL}/${item.image}`}
                                 alt={item.title}
                              />
                           </div>
                           <div className={cx('news-name')}>{item.title}</div>
                           <div className={cx('news-content')}>{item.content}</div>
                        </Link>
                     </div>
                  ))}
               </div>
            </div>
         </div>
      </div>
   );
}

export default NewsEvent;
