import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import vi from 'src/assets/locales/vi.json';
import en from 'src/assets/locales/en.json';

i18n.use(initReactI18next).init({
   fallbackLng: 'en',
   supportedLngs: ['en', 'vi'],
   resources: {
      en: { translation: en },
      vi: { translation: vi },
   },
   interpolation: {
      escapeValue: false,
   },
   detection: {
      order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
      caches: ['cookie', 'localStorage'],
   },
});

export default i18n;
