import Auction from '~/pages/Auction';
import Contact from '~/pages/Contact';
import HealthGuide from '~/pages/HealthGuide';
import Home from '~/pages/Home';
import InfomationClub from '~/pages/InfomationClub';
import LegalInfo from '~/pages/LegalInfo';
import LibDocuments from '~/pages/LibraryDocuments';
import LibImages from '~/pages/LibraryImages';
import LibVideos from '~/pages/LibraryVideos';
import ListMember from '~/pages/ListMember';
import ListPro from '~/pages/ListProduction';
import MemManage from '~/pages/MemberManage';
import MemberRegister from '~/pages/MemberRegister';
//import Members from '~/pages/Members';
import MemberStore from '~/pages/MemberStore';
import NewsEvent from '~/pages/NewsEvent';
import NewsSubpage from '~/pages/NewsSubpage';
import OrientationTarget from '~/pages/OrientationTarget';
import ProSubpage from '~/pages/ProductionSubpage';

// import Publications from '~/pages/Publications';
import SocialAct from '~/pages/SocialAct';

const publicRoutes = [
   { path: '/', component: Home },
   { path: '/infomation-club', component: InfomationClub },
   { path: '/orientation-target', component: OrientationTarget },
   { path: '/news-event', component: NewsEvent },
   { path: '/social-act', component: SocialAct },
   { path: '/legal-info', component: LegalInfo },
   { path: '/health-guide', component: HealthGuide },
   { path: '/news-event/:id', component: NewsSubpage },
   { path: '/list-production', component: ListPro },
   { path: '/production/subpage', component: ProSubpage },
   { path: '/store-member', component: MemberStore },
   { path: '/list-member', component: ListMember },
   { path: '/member-register', component: MemberRegister },
   { path: '/member-manage', component: MemManage },
   // { path: '/members', component: Members },
   // { path: '/publications', component: Publications },
   { path: '/auction', component: Auction },
   { path: '/library/images', component: LibImages },
   { path: '/library/videos', component: LibVideos },
   { path: '/library/documents', component: LibDocuments },
   { path: '/contact', component: Contact },
];

const privateRoutes = [];

export { publicRoutes, privateRoutes };
