import styles from './LibDocuments.module.scss';
import classNames from 'classnames/bind';
import bgPage from 'src/assets/images/bgPage.png';

const cx = classNames.bind(styles);

function LibDocuments() {
   return (
      <div className={cx('wrapper', 'body-proList')}>
         <div className={cx('background-page')} style={{ backgroundImage: `url(${bgPage})` }}>
            <div className={cx('tl-subMenu')}>Tài liệu pháp lý</div>
         </div>
      </div>
   );
}

export default LibDocuments;
