import styles from './MemberStore.module.scss';
import classNames from 'classnames/bind';
import bgPage from 'src/assets/images/bgPage.png';

const cx = classNames.bind(styles);

function MemberStore() {
   return (
      <div className={cx('wrapper', 'body-proList')}>
         <div className={cx('background-page')} style={{ backgroundImage: `url(${bgPage})` }}>
            <div className={cx('tl-subMenu')}>Cửa hàng hội viên</div>
         </div>
      </div>
   );
}

export default MemberStore;
