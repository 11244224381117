import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './ProSubpage.module.scss';
import classNames from 'classnames/bind';
import bgPage from 'src/assets/images/bgPage.png';
import cartW from 'src/assets/images/icons/cart-w.png';
import { faPlus, faSubtract } from '@fortawesome/free-solid-svg-icons';
import { faHeart } from '@fortawesome/free-regular-svg-icons';
import { useEffect, useState } from 'react';

const cx = classNames.bind(styles);

const IMAGE_BASE_URL = '/images';

function ProSubpage() {
   useEffect(() => {
      window.scrollTo(0, 0); // Cuộn trang lên đầu
   }, []);

   const images = [
      `${IMAGE_BASE_URL}/products/vien.png`,
      `${IMAGE_BASE_URL}/products/sam-mo.png`,
      `${IMAGE_BASE_URL}/products/vien.png`,
      `${IMAGE_BASE_URL}/products/sam-mo.png`,
   ];

   const [currentImage, setCurrentImage] = useState(images[0]);
   const [selectedIndex, setSelectedIndex] = useState(0);

   const handleImageClick = (image, index) => {
      setCurrentImage(image);
      setSelectedIndex(index);
   };

   const [quantity, setQuantity] = useState(1);

   const handleIncrease = () => {
      setQuantity((prev) => prev + 1);
   };

   const handleDecrease = () => {
      setQuantity((prev) => (prev > 1 ? prev - 1 : 1));
   };

   const handleInputChange = (e) => {
      const value = Math.max(1, Number(e.target.value));
      setQuantity(value);
   };

   const [activeTab, setActiveTab] = useState(0);

   const handleTabClick = (index) => {
      setActiveTab(index);
   };

   return (
      <div className={cx('wrapper', 'body-proSp')}>
         <div className={cx('background-page')} style={{ backgroundImage: `url(${bgPage})` }}>
            <div className={cx('tl-subMenu')}>Sản phẩm Sâm Ngọc Linh</div>
         </div>
         <div className={cx('main-subpage')}>
            <div className={cx('preview-sp')}>
               <div className={cx('prePro-sp')}>
                  <div className={cx('prePro-tab')}>
                     {images.map((image, index) => (
                        <div
                           key={index}
                           className={cx('prePro-item', { selected: selectedIndex === index })}
                           onClick={() => handleImageClick(image, index)}
                        >
                           <img src={image} alt={`product-${index}`} />
                        </div>
                     ))}
                  </div>
                  <div className={cx('prePro-view')}>
                     <img src={currentImage} alt="Ảnh hiển thị" />
                  </div>
               </div>
               <div className={cx('prePro-info')}>
                  <div className={cx('prePro-star')}>
                     <img src={`${IMAGE_BASE_URL}/icons/rate5.png`} alt={'5 sao'} />
                  </div>
                  <div className={cx('prePro-tl')}>Sâm ngâm mật ong</div>
                  <div className={cx('prePro-number')}>
                     <div className={cx('bg-iconSub')} onClick={handleDecrease}>
                        <FontAwesomeIcon icon={faSubtract} className={cx('icon-subtract')} />
                     </div>
                     <input
                        type="number"
                        className={cx('pro-num')}
                        value={quantity}
                        onChange={handleInputChange}
                        min="1"
                     />
                     <div className={cx('bg-iconAdd')} onClick={handleIncrease}>
                        <FontAwesomeIcon icon={faPlus} className={cx('icon-add')} />
                     </div>
                  </div>
                  <div className={cx('prePro-price')}>
                     <div className={cx('pricePre-now')}>Liên hệ</div>
                     <div className={cx('pricePre-old')}>Giá</div>
                  </div>
                  <div className={cx('prePro-act')}>
                     <div className={cx('btn-pur')}>
                        <div className={cx('pur-tl')}>Mua hàng</div>
                     </div>
                     <div className={cx('btn-tym')}>
                        <FontAwesomeIcon icon={faHeart} className={cx('icon-tym')} />
                     </div>
                  </div>
               </div>
            </div>
            <div className={cx('pro-content')}>
               <div className={cx('tabList-headPro')}>
                  <div className={cx('headPro-item', { active: activeTab === 0 })} onClick={() => handleTabClick(0)}>
                     Giới thiệu
                  </div>
                  <div className={cx('headPro-item', { active: activeTab === 1 })} onClick={() => handleTabClick(1)}>
                     Thông tin chi tiết
                  </div>
                  <div className={cx('headPro-item', { active: activeTab === 2 })} onClick={() => handleTabClick(2)}>
                     Đánh giá [03]
                  </div>
               </div>
               <div className={cx('content-viewPro')}>
                  {activeTab === 0 && (
                     <div className={cx('c-intro')}>
                        <div className={cx('headPro-tl')}>Giới thiệu</div>
                        <div className={cx('body-headPro')}>
                           <div>
                              Rượu Sâm Ngọc Linh củ tươi có hương thơm nồng nàn, vị đắng hơi ngọt và dịu nơi cổ họng,
                              màu sắc trong vàng óng.<br></br>
                              <br></br>
                              Công ty Sâm Ngọc Linh là doanh nghiệp dẫn đầu và sở hữu nguồn sâm Ngọc Linh thuần chủng,
                              giống gốc, không lai tạp với diện tích hơn 600 ha lớn bậc nhất Việt Nam tại đỉnh núi Ngọc
                              Linh. Công ty áp dụng công nghệ bảo tồn, nuôi trồng và phát triển nguồn dược liệu quý này
                              dưới tán rừng có độ phủ trên 70%, ở độ cao 2000m – 2500m tại đỉnh núi Ngọc Linh để nguồn
                              sâm đạt mức tổng hợp dược chất đầy đủ nhất, với hơn 56 hợp chất saponin, đặc biệt là MR2,
                              một trong những dưỡng chất quý hiếm chỉ có ở “Quốc Bảo Việt Nam”.
                              <br></br>
                              <br></br>
                              Để đảm bảo hợp chất saponin, và các dưỡng chất trong Sâm Ngọc Linh, Công ty chỉ kinh doanh
                              những củ có tuổi từ 10 năm trở lên. Trọng lượng từ 30 gram đến 150 gram / củ.
                              <br></br>
                              <br></br>
                              Rượu Nếp Cái Hoa Vàng được lựa chọn, chưng cất theo quy trình và kiểm định nghiêm ngặt, và
                              phải đạt chuẩn theo quy định trước khi được ngâm ủ.
                           </div>
                           <br></br>
                           <img src={`${IMAGE_BASE_URL}/danh-muc/intro.jpg`} alt={'snl'} />
                        </div>
                     </div>
                  )}
                  {activeTab === 1 && (
                     <div className={cx('c-des')}>
                        <div className={cx('headPro-tl')}>Thông tin chi tiết</div>
                        <div className={cx('body-headPro')}>Hiển thị thông tin chi tiết</div>
                     </div>
                  )}
                  {activeTab === 2 && (
                     <div className={cx('c-rate')}>
                        <div className={cx('headPro-tl')}>Đánh giá</div>
                        <div className={cx('body-headPro')}>Hiển thị đánh giá sản phẩm</div>
                     </div>
                  )}
               </div>
            </div>
            <div className={cx('pro-similar')}>
               <div className={cx('headPro-tl')}>Sản phẩm liên quan</div>
               <div className={cx('proBranch-list')}>
                  {/* Các item sản phẩm */}

                  <div className={cx('proBranch-item')}>
                     <div className={cx('pro-star')}>
                        <img src={`${IMAGE_BASE_URL}/icons/rate5.png`} alt={'5 sao'} />
                     </div>
                     <div className={cx('proBranch-cont')}>
                        <div className={cx('proB-img')}>
                           <img src={`${IMAGE_BASE_URL}/products/sam-mo.png`} alt={'to yen'} />
                        </div>
                        <div className={cx('proB-tl')}>Sâm ngâm mật ong</div>
                        <div className={cx('proB-price')}>
                           <div className={cx('price-old')}>Giá</div>
                           <div className={cx('price-now')}>Liên hệ</div>
                        </div>
                        <div className={cx('proB-act')}>
                           <div className={cx('btn-pur')}>
                              <img src={cartW} alt={'cart'} />
                              <div className={cx('pur-tl')}>Mua hàng</div>
                           </div>
                           <div className={cx('btn-tym')}>
                              <FontAwesomeIcon icon={faHeart} className={cx('icon-tym')} />
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className={cx('proBranch-item')}>
                     <div className={cx('pro-star')}>
                        <img src={`${IMAGE_BASE_URL}/icons/rate5.png`} alt={'5 sao'} />
                     </div>
                     <div className={cx('proBranch-cont')}>
                        <div className={cx('proB-img')}>
                           <img src={`${IMAGE_BASE_URL}/products/vien.png`} alt={'vien uong snl'} />
                        </div>
                        <div className={cx('proB-tl')}>Viên uống Sâm Ngọc Linh</div>
                        <div className={cx('proB-price')}>
                           <div className={cx('price-old')}>Giá</div>
                           <div className={cx('price-now')}>Liên hệ</div>
                        </div>
                        <div className={cx('proB-act')}>
                           <div className={cx('btn-pur')}>
                              <img src={cartW} alt={'cart'} />
                              <div className={cx('pur-tl')}>Mua hàng</div>
                           </div>
                           <div className={cx('btn-tym')}>
                              <FontAwesomeIcon icon={faHeart} className={cx('icon-tym')} />
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className={cx('proBranch-item')}>
                     <div className={cx('pro-star')}>
                        <img src={`${IMAGE_BASE_URL}/icons/rate5.png`} alt={'5 sao'} />
                     </div>
                     <div className={cx('proBranch-cont')}>
                        <div className={cx('proB-img')}>
                           <img src={`${IMAGE_BASE_URL}/products/sam-mo.png`} alt={'sam mat ong'} />
                        </div>
                        <div className={cx('proB-tl')}>Sâm ngâm mật ong</div>
                        <div className={cx('proB-price')}>
                           <div className={cx('price-old')}>Giá</div>
                           <div className={cx('price-now')}>Liên hệ</div>
                        </div>
                        <div className={cx('proB-act')}>
                           <div className={cx('btn-pur')}>
                              <img src={cartW} alt={'cart'} />
                              <div className={cx('pur-tl')}>Mua hàng</div>
                           </div>
                           <div className={cx('btn-tym')}>
                              <FontAwesomeIcon icon={faHeart} className={cx('icon-tym')} />
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className={cx('proBranch-item')}>
                     <div className={cx('pro-star')}>
                        <img src={`${IMAGE_BASE_URL}/icons/rate5.png`} alt={'5 sao'} />
                     </div>
                     <div className={cx('proBranch-cont')}>
                        <div className={cx('proB-img')}>
                           <img src={`${IMAGE_BASE_URL}/products/vien.png`} alt={'vien uong snl'} />
                        </div>
                        <div className={cx('proB-tl')}>Viên uống Sâm Ngọc Linh</div>
                        <div className={cx('proB-price')}>
                           <div className={cx('price-old')}>Giá</div>
                           <div className={cx('price-now')}>Liên hệ</div>
                        </div>
                        <div className={cx('proB-act')}>
                           <div className={cx('btn-pur')}>
                              <img src={cartW} alt={'cart'} />
                              <div className={cx('pur-tl')}>Mua hàng</div>
                           </div>
                           <div className={cx('btn-tym')}>
                              <FontAwesomeIcon icon={faHeart} className={cx('icon-tym')} />
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}

export default ProSubpage;
