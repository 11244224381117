import { useState, useEffect, useRef } from 'react';
import classNames from 'classnames/bind';
import styles from './Header.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes, faSearch, faChevronDown, faUser } from '@fortawesome/free-solid-svg-icons';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cart from 'src/assets/images/icons/cart.png';
import logoSNL from 'src/assets/images/logo-snl.jpg';

const cx = classNames.bind(styles);

function Header({ scrollToTop }) {
   const [isOpen, setIsOpen] = useState(false);
   const linksRef = useRef([]);
   const { t, i18n } = useTranslation();
   const [language, setLanguage] = useState('vi');
   const [searchText, setSearchText] = useState('');
   const inputRef = useRef(null);
   const location = useLocation();
   const navigate = useNavigate();

   const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);
      localStorage.setItem('i18nextLng', lng);
   };

   useEffect(() => {
      i18n.changeLanguage('vi');
      localStorage.setItem('i18nextLng', 'vi');
      setLanguage('vi');

      const handleLanguageChange = (lng) => {
         setLanguage(lng);
      };

      i18n.on('languageChanged', handleLanguageChange);

      return () => {
         i18n.off('languageChanged', handleLanguageChange);
      };
   }, [i18n]);

   const toggleNavbar = () => {
      setIsOpen(!isOpen);
   };

   const handleSearchChange = (e) => {
      setSearchText(e.target.value);
   };

   const handleIconClick = () => {
      inputRef.current.focus();
   };

   const handleSearchSubmit = () => {
      console.log('Searching for:', searchText);
   };

   const [isParentActive, setIsParentActive] = useState('');

   const handleMenuClick = (to) => {
      navigate(to);
      setIsOpen(false);
      setIsParentActive('');

      // Gọi scrollToTop nếu nó tồn tại để cuộn lên đầu trang
      if (scrollToTop) {
         scrollToTop();
      }
   };

   const handleSubMenuClick = (menu, path) => {
      handleMenuClick(path);
      setIsParentActive(menu); // Đặt menu đang hoạt động
   };

   useEffect(() => {
      if (location.pathname.includes('/infomation-club') || location.pathname.includes('/orientation-target')) {
         setIsParentActive('introduction');
      } else if (
         location.pathname.includes('/news-event') ||
         location.pathname.includes('/social-act') ||
         location.pathname.includes('/legal-info') ||
         location.pathname.includes('/health-guide')
      ) {
         setIsParentActive('news');
      } else if (location.pathname.includes('/list-member') || location.pathname.includes('/member')) {
         setIsParentActive('members');
      } else if (location.pathname.includes('/list-production') || location.pathname.includes('/store-member')) {
         setIsParentActive('productions');
      } else if (
         location.pathname.includes('/library/images') ||
         location.pathname.includes('/library/videos') ||
         location.pathname.includes('/library/documents')
      ) {
         setIsParentActive('library');
      } else {
         setIsParentActive('');
      }
   }, [location.pathname]);

   return (
      <header className={cx('wrapper')}>
         <div className={cx('h-top')}>
            <div className={cx('m-logo')}>
               <NavLink to="/" className={cx('navbarLogo')} onClick={() => handleMenuClick('/')}>
                  <img src={logoSNL} alt={'logo'} />
               </NavLink>
               <div className={cx('m-contact')}>Liên hệ 090 000 000</div>
            </div>
            <div className={cx('searchBox')}>
               <input
                  type="text"
                  ref={inputRef}
                  value={searchText}
                  onChange={handleSearchChange}
                  placeholder="Tìm kiếm..."
                  className={cx('searchInput')}
               />
               <FontAwesomeIcon icon={faSearch} className={cx('searchIcon')} onClick={handleIconClick} />
            </div>
         </div>
         <div className={cx('inner')}>
            <nav className={cx('navbar')}>
               <div className={cx('navbarLinks', { open: isOpen })}>
                  <NavLink
                     to="/"
                     className={({ isActive }) => cx('menu-item', { active: isActive })}
                     ref={(el) => (linksRef.current[0] = el)}
                     onClick={() => handleMenuClick('/')}
                  >
                     {t('home')}
                  </NavLink>
                  <div className={cx('menu-item', { active: isParentActive === 'introduction' })}>
                     {t('introduction')}
                     <FontAwesomeIcon icon={faChevronDown} className={cx('arrow-menu')} />
                     <div className={cx('subMenu')}>
                        <div className={cx('subMenu-list')}>
                           <NavLink
                              to="/infomation-club"
                              className={({ isActive }) => cx('subMenu-item', { active: isActive })}
                              onClick={() => handleSubMenuClick('members', '/infomation-club')}
                           >
                              Thông tin về hội
                           </NavLink>
                           <div className={cx('line-subMenu')}></div>
                           <NavLink
                              to="/orientation-target"
                              className={({ isActive }) => cx('subMenu-item', { active: isActive })}
                              onClick={() => handleSubMenuClick('members', '/orientation-target')}
                           >
                              Định hướng và mục tiêu
                           </NavLink>
                        </div>
                     </div>
                  </div>
                  <div className={cx('menu-item', { active: isParentActive === 'news' })}>
                     {t('news')}
                     <FontAwesomeIcon icon={faChevronDown} className={cx('arrow-menu')} />
                     <div className={cx('subMenu')}>
                        <div className={cx('subMenu-list')}>
                           <NavLink
                              to="/news-event"
                              className={({ isActive }) => cx('subMenu-item', { active: isActive })}
                              onClick={() => handleSubMenuClick('members', '/news-event')}
                           >
                              Tin tức - Sự kiện
                           </NavLink>
                           <div className={cx('line-subMenu')}></div>
                           <NavLink
                              to="/social-act"
                              className={({ isActive }) => cx('subMenu-item', { active: isActive })}
                              onClick={() => handleSubMenuClick('members', '/social-act')}
                           >
                              Hoạt động xã hội
                           </NavLink>
                           <div className={cx('line-subMenu')}></div>
                           <NavLink
                              to="/legal-info"
                              className={({ isActive }) => cx('subMenu-item', { active: isActive })}
                              onClick={() => handleSubMenuClick('members', '/legal-info')}
                           >
                              Thông tin pháp lý
                           </NavLink>
                           <div className={cx('line-subMenu')}></div>
                           <NavLink
                              to="/health-guide"
                              className={({ isActive }) => cx('subMenu-item', { active: isActive })}
                              onClick={() => handleSubMenuClick('members', '/health-guide')}
                           >
                              Cẩm nang sức khỏe
                           </NavLink>
                        </div>
                     </div>
                  </div>
                  {/* Members Menu */}
                  <div className={cx('menu-item', { active: isParentActive === 'members' })}>
                     {t('members')}
                     <FontAwesomeIcon icon={faChevronDown} className={cx('arrow-menu')} />
                     <div className={cx('subMenu')}>
                        <div className={cx('subMenu-list')}>
                           <NavLink
                              to="/list-member"
                              className={({ isActive }) => cx('subMenu-item', { active: isActive })}
                              onClick={() => handleSubMenuClick('members', '/list-member')}
                           >
                              Danh sách hội viên
                           </NavLink>
                           <div className={cx('line-subMenu')}></div>
                           <NavLink
                              to="/member-register"
                              className={({ isActive }) => cx('subMenu-item', { active: isActive })}
                              onClick={() => handleSubMenuClick('members', '/member-register')}
                           >
                              Đăng ký hội viên
                           </NavLink>
                           <div className={cx('line-subMenu')}></div>
                           <NavLink
                              to="/member-manage"
                              className={({ isActive }) => cx('subMenu-item', { active: isActive })}
                              onClick={() => handleSubMenuClick('members', '/member-manage')}
                           >
                              Quản lý hội viên
                           </NavLink>
                        </div>
                     </div>
                  </div>
                  {/* Productions Menu */}
                  <div className={cx('menu-item', { active: isParentActive === 'productions' })}>
                     {t('productions')}
                     <FontAwesomeIcon icon={faChevronDown} className={cx('arrow-menu')} />
                     <div className={cx('subMenu')}>
                        <div className={cx('subMenu-list')}>
                           <NavLink
                              to="/list-production"
                              className={({ isActive }) => cx('subMenu-item', { active: isActive })}
                              onClick={() => handleSubMenuClick('productions', '/list-production')}
                           >
                              Danh sách sản phẩm
                           </NavLink>
                           <div className={cx('line-subMenu')}></div>
                           <NavLink
                              to="/store-member"
                              className={({ isActive }) => cx('subMenu-item', { active: isActive })}
                              onClick={() => handleSubMenuClick('productions', '/store-member')}
                           >
                              Cửa hàng hội viên
                           </NavLink>
                        </div>
                     </div>
                  </div>
                  <NavLink
                     to="/auction"
                     className={({ isActive }) => cx('menu-item', { active: isActive })}
                     ref={(el) => (linksRef.current[5] = el)}
                     onClick={() => handleMenuClick('/auction')}
                  >
                     {t('auction')}
                  </NavLink>
                  {/* Library Menu */}
                  <div className={cx('menu-item', { active: isParentActive === 'library' })}>
                     {t('library')}
                     <FontAwesomeIcon icon={faChevronDown} className={cx('arrow-menu')} />
                     <div className={cx('subMenu')}>
                        <div className={cx('subMenu-list')}>
                           <NavLink
                              to="/library/images"
                              className={({ isActive }) => cx('subMenu-item', { active: isActive })}
                              onClick={() => handleSubMenuClick('library', '/library/images')}
                           >
                              Ảnh
                           </NavLink>
                           <div className={cx('line-subMenu')}></div>
                           <NavLink
                              to="/library/videos"
                              className={({ isActive }) => cx('subMenu-item', { active: isActive })}
                              onClick={() => handleSubMenuClick('library', '/library/videos')}
                           >
                              Video
                           </NavLink>
                           <div className={cx('line-subMenu')}></div>
                           <NavLink
                              to="/library/documents"
                              className={({ isActive }) => cx('subMenu-item', { active: isActive })}
                              onClick={() => handleSubMenuClick('library', '/library/documents')}
                           >
                              Tài liệu pháp lý
                           </NavLink>
                        </div>
                     </div>
                  </div>
                  <NavLink
                     to="/contact"
                     className={({ isActive }) => cx('menu-item', { active: isActive })}
                     ref={(el) => (linksRef.current[7] = el)}
                     onClick={() => handleMenuClick('/contact')}
                  >
                     {t('contact_us')}
                  </NavLink>
               </div>

               <div className={cx('login-cart')}>
                  <div className={cx('login-a')}>
                     <FontAwesomeIcon icon={faUser} className={cx('icon-user')} />
                     <div className={cx('login-text')}>Đăng nhập</div>
                  </div>
                  <div className={cx('cart')}>
                     <div className={cx('icon-cart')}>
                        <img src={cart} alt={'cart'} />
                     </div>
                     <div className={cx('notify')}>10</div>
                  </div>
                  {/* <div className={cx('languageSwitcher')}>
                     <button onClick={() => changeLanguage(language === 'vi' ? 'en' : 'vi')}>
                        {language === 'vi' ? 'EN' : 'VI'}
                        <img
                           src={language === 'vi' ? '/images/uk-flag.png' : '/images/vietnam-flag.png'}
                           alt={language === 'vi' ? 'English' : 'Vietnamese'}
                           className={cx('flagIcon')}
                        />
                     </button>
                  </div> */}
               </div>
               <div className={cx('navbarToggle')} onClick={toggleNavbar}>
                  {isOpen ? (
                     <FontAwesomeIcon icon={faTimes} className={cx('icon', 'icon-open')} />
                  ) : (
                     <FontAwesomeIcon icon={faBars} className={cx('icon', 'icon-close')} />
                  )}
               </div>
            </nav>
         </div>
      </header>
   );
}

export default Header;
