import styles from './MemberRegister.module.scss';
import classNames from 'classnames/bind';
import { useEffect } from 'react';
import bgPage from 'src/assets/images/bgPage.png';

const cx = classNames.bind(styles);

function MemberRegister() {
   useEffect(() => {
      window.scrollTo(0, 0); // Cuộn trang lên đầu
   }, []);
   return (
      <div className={cx('wrapper', 'body-proList')}>
         <div className={cx('background-page')} style={{ backgroundImage: `url(${bgPage})` }}>
            <div className={cx('tl-subMenu')}>Đăng ký hội viên</div>
         </div>
         <iframe
            src="https://eznote.vn/"
            width="100%"
            height="1000px"
            style={{ display: 'block', border: 'none', margin: '20px auto' }}
            title="Đăng ký hội viên"
         />
      </div>
   );
}

export default MemberRegister;
