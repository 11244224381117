import Footer from './Footer';
import Header from './Header';
import styles from './DefaultLayout.module.scss';
import classNames from 'classnames/bind';
import { useRef } from 'react';

const cx = classNames.bind(styles);

function DefaultLayout({ children }) {
   // Tạo ref cho phần thân trang
   const bodyRef = useRef(null);

   // Hàm để cuộn đến đầu trang (có trừ đi chiều cao cố định của header)
   const scrollToTop = () => {
      if (bodyRef.current) {
         const offsetPosition = bodyRef.current.offsetTop - 120;
         window.scrollTo({
            top: offsetPosition,
            behavior: 'auto',
         });
      }
   };

   return (
      <div>
         <Header scrollToTop={scrollToTop} />
         <div ref={bodyRef} className={cx('body-page')}>
            {children}
         </div>
         <Footer />
      </div>
   );
}

export default DefaultLayout;
