import styles from './LibVideos.module.scss';
import classNames from 'classnames/bind';
import bgPage from 'src/assets/images/bgPage.png';

const cx = classNames.bind(styles);

function LibVideos() {
   return (
      <div className={cx('wrapper', 'body-proList')}>
         <div className={cx('background-page')} style={{ backgroundImage: `url(${bgPage})` }}>
            <div className={cx('tl-subMenu')}>Thư viện Video</div>
         </div>
         <div className={cx('f-video')}>
            <iframe
               src="https://www.youtube.com/embed/Q_cNYdsmWNk"
               title="YouTube video player"
               frameBorder="0"
               allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
               allowFullScreen
            ></iframe>
         </div>
      </div>
   );
}

export default LibVideos;
