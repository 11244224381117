import styles from './OrientationTarget.module.scss';
import classNames from 'classnames/bind';
import bgPage from 'src/assets/images/bgPageInfoClub.png';

const cx = classNames.bind(styles);

function OrientationTarget() {
   return (
      <div className={cx('wrapper', 'body-proList')}>
         <div className={cx('background-page')} style={{ backgroundImage: `url(${bgPage})` }}>
            <div className={cx('tl-subMenu')}>Định hướng và mục tiêu</div>
         </div>
      </div>
   );
}

export default OrientationTarget;
