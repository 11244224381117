import styles from './InfomationClub.module.scss';
import classNames from 'classnames/bind';
import bgPage from 'src/assets/images/bgPageInfoClub.png';
import introImg from 'src/assets/images/intro-sam.png';
import trongSam from 'src/assets/images/trong-sam.png';
import kdSam from 'src/assets/images/kd-sam.png';
import cheBienSam from 'src/assets/images/che-bien-sam.png';
import bgClubSam from 'src/assets/images/hoi-sam.png';
import bgHoa from 'src/assets/images/hoa-la.png';
import { useEffect } from 'react';

const cx = classNames.bind(styles);

function InfomationClub() {
   useEffect(() => {
      window.scrollTo(0, 0); // Cuộn trang lên đầu
   }, []);
   return (
      <div className={cx('wrapper')}>
         <div className={cx('background-page')} style={{ backgroundImage: `url(${bgPage})` }}>
            <div className={cx('tl-subMenu')}>Thông tin về hội</div>
         </div>
         <div className={cx('body-intro')}>
            <div className={cx('f-intro')}>
               <div className={cx('cont-intro')}>
                  <div className={cx('img-intro')}>
                     <img src={introImg} alt={'Gioi thieu SNL'} />
                  </div>
                  <div className={cx('text-intro')}>
                     <div className={cx('tl-intro')}>Giới thiệu</div>
                     <div className={cx('des-intro')}>
                        Sâm Ngọc Linh là một loại dược liệu quý hiếm, có nguồn gốc và điều kiện sinh trưởng đặc biệt tại
                        vùng núi Ngọc Linh thuộc hai tỉnh Quảng Nam và Kon Tum.
                        <br></br>
                        <br></br>Tỉnh Quảng Nam đã qui hoạch hơn 19 ngàn héc ta rừng để bảo tồn và phát trển cây sâm
                        Ngọc Linh. Tỉnh Quảng Nam hiện có hơn một ngàn hộ trồng sâm và hàng chục doanh nghiệp tham gia
                        kinh doanh, chế biến Sâm Ngọc Linh, góp phần phát triển kinh tế xã hội địa phương và thúc đẩy
                        chiến lược phát triển Sâm Ngọc Linh của đất nước.
                     </div>
                  </div>
               </div>
               <div className={cx('f-soAT')}>
                  <div className={cx('view-soAT')}>
                     <div className={cx('item-soAT')}>
                        <div className={cx('num-soAT')}>19000</div>
                        <div className={cx('text-soAT')}>Hecta rừng bảo tồn và phát triển sâm</div>
                     </div>
                     <div className={cx('item-soAT')}>
                        <div className={cx('num-soAT', 'hl-num')}>1000+</div>
                        <div className={cx('text-soAT')}>Hộ trồng sâm</div>
                     </div>
                     <div className={cx('item-soAT')}>
                        <div className={cx('num-soAT')}>20+</div>
                        <div className={cx('text-soAT')}>Doanh nghiệp tham gia</div>
                     </div>
                  </div>
               </div>
            </div>
            <div className={cx('f-linhVuc')}>
               <div className={cx('text-linhVuc')}>
                  Hội Sâm Ngọc Linh Quảng Nam là nơi tập hợp người trồng sâm, các hợp tác xã, doanh nghiệp hoạt động
                  trong lĩnh vực trồng, kinh doanh và chế biến sâm Ngọc Linh.
               </div>
               <div className={cx('list-linhVuc')}>
                  <div className={cx('item-linhVuc')}>
                     <img src={trongSam} alt={'Trong Sam'} />
                     <div className={cx('tl-linhVuc')}>Trồng sâm</div>
                  </div>
                  <div className={cx('item-linhVuc')}>
                     <img src={kdSam} alt={'Kinh doanh Sam'} />
                     <div className={cx('tl-linhVuc')}>Kinh doanh sâm</div>
                  </div>
                  <div className={cx('item-linhVuc')}>
                     <img src={cheBienSam} alt={'Che bien Sam'} />
                     <div className={cx('tl-linhVuc')}>Chế biến sâm</div>
                  </div>
               </div>
            </div>
            <div className={cx('f-final')} style={{ backgroundImage: `url(${bgHoa})` }}>
               <div className={cx('text-final')}>
                  Hội Sâm Ngọc Linh Quảng Nam hoạt động với mục tiêu bảo tồn nguồn gen quý của cây Sâm Ngọc Linh; phát
                  triển sản xuất và chế biến Sâm Ngọc Linh trở thành một trong những ngành kinh tế mũi nhọn, góp phần
                  nâng cao thu nhập cho người dân, nhất là vùng đồng bào dân tộc miền núi; gắn việc sản xuất Sâm Ngọc
                  Linh với việc quản lý, bảo vệ và phát triển rừng bền vững; giữ gìn và phát huy bản sắc văn hóa địa
                  phương; nâng cao vị thế của Sâm Ngọc Linh - Quốc bảo Việt Nam trên trường quốc tế.
               </div>
               <img src={bgClubSam} alt={'Hoi Sam'} />
            </div>
         </div>
      </div>
   );
}

export default InfomationClub;
