import { faFacebookF, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import styles from './Footer.module.scss';
import classNames from 'classnames/bind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import bgFoot from 'src/assets/images/bg-foot.png';

const cx = classNames.bind(styles);

function Footer() {
   return (
      <div className={cx('wrapper', 'footer')} style={{ backgroundImage: `url(${bgFoot})` }}>
         <div className={cx('container', 'info-body')}>
            <div className={cx('info-ft')}>
               <div className={cx('tl-info')}>Sâm Ngọc Linh Việt Nam</div>
               <div className={cx('info-item')}>
                  <div className={cx('info-name')}>Cửa hàng chính:&nbsp;</div>
                  <div className={cx('info-cont')}>Sâm Ngọc Linh, Nam Trà My, Quảng Nam</div>
               </div>
               <div className={cx('info-item')}>
                  <div className={cx('info-name')}>Cơ sở 2:&nbsp;</div>
                  <div className={cx('info-cont')}>Quảng Nam, Việt Nam</div>
               </div>
               <div className={cx('info-item')}>
                  <div className={cx('info-name')}>Điện thoại:&nbsp;</div>
                  <div className={cx('info-cont')}>0988 888 888</div>
               </div>
               <div className={cx('info-item')}>
                  <div className={cx('info-name')}>Email:&nbsp;</div>
                  <div className={cx('info-cont')}>samngoclinh@gmail.com</div>
               </div>
            </div>
            <div className={cx('policy-ft')}>
               <div className={cx('tl-info2')}>Chính sách</div>
               <div className={cx('info-item')}>
                  <div className={cx('info-cont')}>Chính sách đổi trả</div>
               </div>
               <div className={cx('info-item')}>
                  <div className={cx('info-cont')}>Chính sách bảo mật</div>
               </div>
               <div className={cx('info-item')}>
                  <div className={cx('info-cont')}>Chính sách giao hàng</div>
               </div>
            </div>
            <div className={cx('sp-guest')}>
               <div className={cx('tl-info2')}>Hỗ trợ khách hàng</div>
               <div className={cx('info-item')}>
                  <div className={cx('info-cont')}>Hướng dẫn mua hàng</div>
               </div>
               <div className={cx('info-item')}>
                  <div className={cx('info-cont')}>Hướng dẫn thanh toán</div>
               </div>
            </div>
            <div className={cx('connect-ft')}>
               <div className={cx('tl-info2')}>Kết nối với chúng tôi</div>
               <div className={cx('list-mxh')}>
                  <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                     <FontAwesomeIcon icon={faFacebookF} className={cx('icon-mxh')} />
                  </a>
                  <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                     <FontAwesomeIcon icon={faInstagram} className={cx('icon-mxh')} />
                  </a>
                  <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer">
                     <FontAwesomeIcon icon={faYoutube} className={cx('icon-mxh')} />
                  </a>
               </div>
            </div>
         </div>
      </div>
   );
}

export default Footer;
