import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './ListPro.module.scss';
import classNames from 'classnames/bind';
import { useState, useRef, useEffect } from 'react';
import bgPage from 'src/assets/images/bgPage.png';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { faHeart } from '@fortawesome/free-regular-svg-icons';
import cartW from 'src/assets/images/icons/cart-w.png';
import { NavLink } from 'react-router-dom';

const cx = classNames.bind(styles);

const IMAGE_BASE_URL = '/images';

function ListPro() {
   useEffect(() => {
      window.scrollTo(0, 0); // Cuộn trang lên đầu
   }, []);
   const [searchText, setSearchText] = useState('');
   const inputRef = useRef(null);
   const handleSearchChange = (e) => {
      setSearchText(e.target.value);
   };

   const handleIconClick = () => {
      inputRef.current.focus();
   };
   const [price, setPrice] = useState(3000000);

   const handleSliderChange = (event) => {
      setPrice(event.target.value);
   };

   const listRef = useRef(null);

   // Tạo mảng sản phẩm mẫu
   const products = [
      {
         id: 1,
         name: 'Viên uống sâm Ngọc Linh',
         oldPrice: 'Giá',
         newPrice: 'Liên hệ',
         imgSrc: `${IMAGE_BASE_URL}/products/vien.png`,
         starSrc: `${IMAGE_BASE_URL}/icons/rate5.png`,
      },
      {
         id: 2,
         name: 'Sâm ngâm mật ong',
         oldPrice: 'Giá',
         newPrice: 'Liên hệ',
         imgSrc: `${IMAGE_BASE_URL}/products/sam-mo.png`,
         starSrc: `${IMAGE_BASE_URL}/icons/rate5.png`,
      },
      {
         id: 3,
         name: 'Cao lá sâm Ngọc Linh VINASAM',
         oldPrice: 'Giá',
         newPrice: 'Liên hệ',
         imgSrc: `${IMAGE_BASE_URL}/products/caola.png`,
         starSrc: `${IMAGE_BASE_URL}/icons/rate5.png`,
      },
      {
         id: 4,
         name: 'Thực phẩm chức năng SAVIGOUT SAMSAM',
         oldPrice: 'Giá',
         newPrice: 'Liên hệ',
         imgSrc: `${IMAGE_BASE_URL}/products/gout.png`,
         starSrc: `${IMAGE_BASE_URL}/icons/rate5.png`,
      },
      {
         id: 5,
         name: 'Viên ngậm SAVINA SAMSAM',
         oldPrice: 'Giá',
         newPrice: 'Liên hệ',
         imgSrc: `${IMAGE_BASE_URL}/products/vienngam.png`,
         starSrc: `${IMAGE_BASE_URL}/icons/rate5.png`,
      },
      {
         id: 6,
         name: 'Rượu sâm Ngọc Linh SAMSAM',
         oldPrice: 'Giá',
         newPrice: 'Liên hệ',
         imgSrc: `${IMAGE_BASE_URL}/products/ruoured.png`,
         starSrc: `${IMAGE_BASE_URL}/icons/rate5.png`,
      },
      {
         id: 7,
         name: 'Đẳng sâm Ngọc Linh VINASAM',
         oldPrice: 'Giá',
         newPrice: 'Liên hệ',
         imgSrc: `${IMAGE_BASE_URL}/products/dangsam.png`,
         starSrc: `${IMAGE_BASE_URL}/icons/rate5.png`,
      },
      {
         id: 8,
         name: 'Kẹo lạc Sâm Ngọc Linh SAMVINA',
         oldPrice: 'Giá',
         newPrice: 'Liên hệ',
         imgSrc: `${IMAGE_BASE_URL}/products/keolac.png`,
         starSrc: `${IMAGE_BASE_URL}/icons/rate5.png`,
      },
      {
         id: 9,
         name: 'Hoa Sâm Ngọc Linh TRAMY',
         oldPrice: 'Giá',
         newPrice: 'Liên hệ',
         imgSrc: `${IMAGE_BASE_URL}/products/hoaNgoclinh.png`,
         starSrc: `${IMAGE_BASE_URL}/icons/rate5.png`,
      },
      {
         id: 10,
         name: 'Sâm Ngọc Linh ngâm mật ong TRIMICO',
         oldPrice: 'Giá',
         newPrice: 'Liên hệ',
         imgSrc: `${IMAGE_BASE_URL}/products/samngammatong.png`,
         starSrc: `${IMAGE_BASE_URL}/icons/rate5.png`,
      },
      {
         id: 11,
         name: 'Thạch sâm Ngọc Linh TRIMICO',
         oldPrice: 'Giá',
         newPrice: 'Liên hệ',
         imgSrc: `${IMAGE_BASE_URL}/products/thachsam.png`,
         starSrc: `${IMAGE_BASE_URL}/icons/rate5.png`,
      },
      {
         id: 12,
         name: 'Trà sâm Ngọc Linh TRIMICO',
         oldPrice: 'Giá',
         newPrice: 'Liên hệ',
         imgSrc: `${IMAGE_BASE_URL}/products/trasam.png`,
         starSrc: `${IMAGE_BASE_URL}/icons/rate5.png`,
      },
      {
         id: 13,
         name: 'Sâm Ngọc Linh ngâm mật ong TRIMICO',
         oldPrice: 'Giá',
         newPrice: 'Liên hệ',
         imgSrc: `${IMAGE_BASE_URL}/products/samngammatong2.png`,
         starSrc: `${IMAGE_BASE_URL}/icons/rate5.png`,
      },
      {
         id: 14,
         name: 'Yến sào Sâm Ngọc Linh VINAPAX',
         oldPrice: 'Giá',
         newPrice: 'Liên hệ',
         imgSrc: `${IMAGE_BASE_URL}/products/yensao.png`,
         starSrc: `${IMAGE_BASE_URL}/icons/rate5.png`,
      },
   ];

   const itemsPerPage = 6; // Số lượng sản phẩm mỗi trang
   const totalItems = products.length; // Tổng số sản phẩm (ở đây là 20)
   const totalPages = Math.ceil(totalItems / itemsPerPage);

   const [currentPage, setCurrentPage] = useState(1);

   // Lọc sản phẩm dựa trên trang hiện tại
   const currentItems = products.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

   // Xử lý chuyển trang
   const handlePageChange = (page) => {
      setCurrentPage(page);
      // Cuộn đến đầu danh sách và bù trừ chiều cao menu cố định 120px
      const offsetPosition = listRef.current.getBoundingClientRect().top + window.pageYOffset - 120;

      window.scrollTo({
         top: offsetPosition,
         behavior: 'smooth',
      });
   };

   const handleNavigation = (e) => {
      // Nếu người dùng nhấn vào nút "Mua hàng" hoặc "Yêu thích", không chuyển trang
      e.preventDefault();
   };

   return (
      <div className={cx('wrapper', 'body-proList')}>
         <div className={cx('background-page')} style={{ backgroundImage: `url(${bgPage})` }}>
            <div className={cx('tl-subMenu')}>Danh sách sản phẩm</div>
         </div>
         <div className={cx('total-page')}>
            <div className={cx('sidebar-pro')}>
               <div className={cx('searchBox')}>
                  <input
                     type="text"
                     ref={inputRef}
                     value={searchText}
                     onChange={handleSearchChange}
                     placeholder="Nhập tên sản phẩm"
                     className={cx('searchInput')}
                  />
                  <FontAwesomeIcon icon={faSearch} className={cx('searchIcon')} onClick={handleIconClick} />
               </div>
               <div className={cx('btn-search')}>Tìm kiếm</div>
               <div className={cx('line-pro')}></div>
               <div className={cx('proList-tl')}>Danh mục sản phẩm</div>
               <div className={cx('check-proList')}>
                  <div className={cx('check-item')}>
                     <input type="checkbox" className={cx('check-box')} />
                     <div className={cx('namePro-type')}>Trà sâm</div>
                  </div>
                  <div className={cx('check-item')}>
                     <input type="checkbox" className={cx('check-box')} />
                     <div className={cx('namePro-type')}>Rượu sâm</div>
                  </div>
                  <div className={cx('check-item')}>
                     <input type="checkbox" className={cx('check-box')} />
                     <div className={cx('namePro-type')}>Kẹo sâm</div>
                  </div>
                  <div className={cx('check-item')}>
                     <input type="checkbox" className={cx('check-box')} />
                     <div className={cx('namePro-type')}>Sâm tươi</div>
                  </div>
                  <div className={cx('check-item')}>
                     <input type="checkbox" className={cx('check-box')} />
                     <div className={cx('namePro-type')}>Viên nang sâm</div>
                  </div>
               </div>
               <div className={cx('line-pro')}></div>
               <div className={cx('proList-tl')}>Giá</div>
               <div className={cx('total-price')}>
                  <div className={cx('price-label')}>
                     <span>0 vnđ</span>
                     <span>{Number(price).toLocaleString()} vnđ</span>
                  </div>
                  <input
                     type="range"
                     min="0"
                     max="5000000"
                     step="100000"
                     value={price}
                     onChange={handleSliderChange}
                     className={cx('price-range')}
                  />
               </div>
               <div className={cx('line-pro')}></div>
               <div className={cx('proList-tl')}>Sản phẩm đề xuất</div>
               <div className={cx('proDX-list')}>
                  <div className={cx('pro-de-xuat')}>
                     <div className={cx('proDX-img')}>
                        <img src={`${IMAGE_BASE_URL}/products/sam-mo.png`} alt={'to yen'} />
                     </div>
                     <div className={cx('proDX-cont')}>
                        <div className={cx('proDX-tl')}>Sâm ngâm mật ong</div>
                        <div className={cx('proDX-price')}>
                           <div className={cx('priceDX-old')}>Giá</div>
                           <div className={cx('priceDX-now')}>Liên hệ</div>
                        </div>
                     </div>
                  </div>
                  <div className={cx('pro-de-xuat')}>
                     <div className={cx('proDX-img')}>
                        <img src={`${IMAGE_BASE_URL}/products/sam-mo.png`} alt={'to yen'} />
                     </div>
                     <div className={cx('proDX-cont')}>
                        <div className={cx('proDX-tl')}>Sâm ngâm mật ong</div>
                        <div className={cx('proDX-price')}>
                           <div className={cx('priceDX-old')}>Giá</div>
                           <div className={cx('priceDX-now')}>Liên hệ</div>
                        </div>
                     </div>
                  </div>
                  <div className={cx('pro-de-xuat')}>
                     <div className={cx('proDX-img')}>
                        <img src={`${IMAGE_BASE_URL}/products/sam-mo.png`} alt={'to yen'} />
                     </div>
                     <div className={cx('proDX-cont')}>
                        <div className={cx('proDX-tl')}>Sâm ngâm mật ong</div>
                        <div className={cx('proDX-price')}>
                           <div className={cx('priceDX-old')}>Giá</div>
                           <div className={cx('priceDX-now')}>Liên hệ</div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className={cx('pro-content')}>
               <div ref={listRef} className={cx('proBranch-list')}>
                  {currentItems.map((product) => (
                     <NavLink to="/production/subpage" key={product.id} className={cx('proBranch-item')}>
                        <div className={cx('pro-star')}>
                           <img src={product.starSrc} alt="5 sao" />
                        </div>
                        <div className={cx('proBranch-cont')}>
                           <div className={cx('proB-img')}>
                              <img src={product.imgSrc} alt="to yen" />
                           </div>
                           <div className={cx('proB-tl')}>{product.name}</div>
                           <div className={cx('proB-price')}>
                              <div className={cx('price-old')}>{product.oldPrice.toLocaleString()}</div>
                              <div className={cx('price-now')}>{product.newPrice.toLocaleString()}</div>
                           </div>
                           <div className={cx('proB-act')}>
                              <div className={cx('btn-pur')} onClick={(e) => handleNavigation(e)}>
                                 <img src={cartW} alt="cart" />
                                 <div className={cx('pur-tl')}>Mua hàng</div>
                              </div>
                              <div className={cx('btn-tym')} onClick={(e) => handleNavigation(e)}>
                                 <FontAwesomeIcon icon={faHeart} className={cx('icon-tym')} />
                              </div>
                           </div>
                        </div>
                     </NavLink>
                  ))}
               </div>

               {/* Nút phân trang */}
               <div className={cx('pagination')}>
                  {[...Array(totalPages)].map((_, index) => (
                     <button
                        key={index}
                        className={cx('page-button', { active: currentPage === index + 1 })}
                        onClick={() => handlePageChange(index + 1)}
                     >
                        {index + 1}
                     </button>
                  ))}
               </div>
            </div>
         </div>
      </div>
   );
}

export default ListPro;
