import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import classNames from 'classnames/bind';
import bgPage from 'src/assets/images/bgPageEvent.png';

const cx = classNames.bind();

function NewsSubpage() {
   const { id } = useParams();
   const [newsItem, setNewsItem] = useState(null);

   useEffect(() => {
      window.scrollTo(0, 0); // Cuộn trang lên đầu
   }, []);

   useEffect(() => {
      fetch('/newsData/news.json')
         .then((response) => response.json())
         .then((data) => {
            const item = data.find((news) => news.id === parseInt(id, 10));
            setNewsItem(item);
         });
   }, [id]);

   if (!newsItem) {
      return <div>Loading...</div>;
   }
   return (
      <div className={cx('wrapper')}>
         <div className={cx('background-page')} style={{ backgroundImage: `url(${bgPage})` }}>
            <div className={cx('tl-subMenu')}>{newsItem.title}</div>
         </div>
         <div className={cx('body-sp')}>
            <div dangerouslySetInnerHTML={{ __html: newsItem.body }} />
         </div>
      </div>
   );
}

export default NewsSubpage;
