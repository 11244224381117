import styles from './Auction.module.scss';
import classNames from 'classnames/bind';
import { useEffect } from 'react';
import bgPage from 'src/assets/images/bgPage.png';

const cx = classNames.bind(styles);

function Auction() {
   useEffect(() => {
      window.scrollTo(0, 0); // Cuộn trang lên đầu
   }, []);
   return (
      <div className={cx('wrapper', 'body-proList')}>
         <div className={cx('background-page')} style={{ backgroundImage: `url(${bgPage})` }}>
            <div className={cx('tl-subMenu')}>Đấu giá</div>
         </div>
      </div>
   );
}

export default Auction;
